import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
//import UploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
//import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
//import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';

//import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
//import ListPlugin from '@ckeditor/ckeditor5-list/src/list';

import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
//import Image from '@ckeditor/ckeditor5-image/src/image';
import Font from '@ckeditor/ckeditor5-font/src/font';

import InsertImage from './plugins/insertimage';
import Symbols from './plugins/symbols/symbols';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import { loadKeyboard } from './plugins/keyboard/keyboardscript';
import { initbuilderbuttons } from './plugins/keyboard/keyboardbuilder';


import samplepdfs from './pdfsample';

//import LATEX from 'latex'

//import 'web-overleaf/app';

import './styles/index.scss';

import $ from 'jquery';
//import { createPromiseCapability } from 'pdfjs-dist';

/*
const pdfjsLib = require("pdfjs-dist/legacy/build/pdf.js");
console.log("pdf.js:", pdfjsLib);
pdfjsLib.GlobalWorkerOptions.workerSrc = "pdf.worker.bundle.js";
*/

//import ObservableSlim from 'observable-slim';

console.log("Javascript <3");
//console-log(Overleaf);

//var windowHeight = window.innerHeight;
//document.querySelector('html').style.height= windowHeight + "px";


/*
const latex = require('node-latex')
const fs = require('fs')
 
const input = fs.createReadStream('input.tex')
const output = fs.createWriteStream('output.pdf')
const pdf = latex(input)
 
pdf.pipe(output)
pdf.on('error', err => console.error(err))
pdf.on('finish', () => console.log('PDF generated!'))
*/

var projectName = "alligator-test";


/*
document.getElementById("test").addEventListener("click", function(e) {
  // insert pdf in pdf-js-viewer
  console.log("PDF DATA URL:", pdf_dataurl);
  var blob = dataURLtoBlob(pdf_dataurl);
  var objectURL = URL.createObjectURL(blob);
  document.getElementById("pdf-js-viewer").src = objectURL;

  e.preventDefault();
});


function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}
*/



var classToLatex = {
  "integral": function(node){
    var children = node.childNodes;
    console.log("symbol-children:", children);
    if ( isEmpty(children[1]) && isEmpty(children[2]) ) {
      // both small-boxes are empty
      return "\\int{" + ckTOlatex(children[3]) + "}"
    } else {
      return "\\int\\limits_{" + ckTOlatex(children[1]) + "}^{" + ckTOlatex(children[2]) + "}{" + ckTOlatex(children[3]) + "}"
    }
  },
  "sum": function(node){
    var children = node.childNodes;
    console.log("symbol-children:", children);
    if ( isEmpty(children[1]) && isEmpty(children[2]) ) {
      // both small-boxes are empty
      return "\\sum{" + ckTOlatex(children[3]) + "}"
    } else {
      return "\\sum\\limits_{" + ckTOlatex(children[1]) + "}^{" + ckTOlatex(children[2]) + "}{" + ckTOlatex(children[3]) + "}"
    }
  },
};

var ptToRem = 0.1;

var ptToLatex = {
  "5": "\\tiny ",
  "7": "\\scriptsize ",
  "8": "\\footnotesize ",
  "9": "\\small ",
  "10": "\\normalsize ",
  "12": "\\large ",
  "14": "\\Large ",
  "17": "\\LARGE ",
  "20": "\\huge ",
  "25": "\\Huge ",
};

var ckDependencies = ['xcolor', 'amssymb'];

var findMathInText = true;

//$( noIndent() );

function noIndent(){
  document.querySelector('.ck-content p').style.textIndent = "0";
}

function indent(){
  document.querySelector('.ck-content p').style.textIndent = "var(--indent)";
}

function noWordBreak(){
  document.querySelector('.ck-content p').style.width= "max-content";
}

function fullWordBreak(){
  document.querySelector('.ck-content p').style.width= "100%";
}

ClassicEditor
    .create( document.querySelector( '#editor'), {
        // The plugins are now passed directly to .create().
        plugins: [
            EssentialsPlugin,
            AutoformatPlugin,
            BoldPlugin,
            ItalicPlugin,
            BlockQuotePlugin,
            HeadingPlugin,
            ImagePlugin,
            ImageCaptionPlugin,
            ImageStylePlugin,
            ImageToolbarPlugin,
            //EasyImagePlugin,
            //ImageUploadPlugin,
            //LinkPlugin,
            //ListPlugin,
            ParagraphPlugin,
            //UploadAdapterPlugin,
            InsertImage,
            Font,
            Alignment,
            Symbols
        ],

        fontFamily: {
            options: [
              'default',
		          'Arial, Helvetica, sans-serif',
		          'Courier New, Courier, monospace',
		          'Georgia, serif',
		          'Lucida Sans Unicode, Lucida Grande, sans-serif',
		          'Tahoma, Geneva, sans-serif',
		          'Times New Roman, Times, serif',
		          'Trebuchet MS, Helvetica, sans-serif',
		          'Verdana, Geneva, sans-serif',
              'Ubuntu, Arial, sans-serif',
              'LMRoman10-Regular',
              'computer_modernmedium'
            ]
        },

        fontSize: {
          options: [
            {title: '5pt', model: (ptToRem*5).toString()+'rem'},
            {title: '7pt', model: (ptToRem*7).toString()+'rem'},
            {title: '8pt', model: (ptToRem*8).toString()+'rem'},
            {title: '9pt', model: (ptToRem*9).toString()+'rem'},
            {title: '10pt (default)', model: (ptToRem*10).toString()+'rem'},
            //'default',
            {title: '12pt', model: (ptToRem*12).toString()+'rem'},
            {title: '14pt', model: (ptToRem*14).toString()+'rem'},
            {title: '17pt', model: (ptToRem*17).toString()+'rem'},
            {title: '20pt', model: (ptToRem*20).toString()+'rem'},
            {title: '25pt', model: (ptToRem*25).toString()+'rem'},
          ],
        },

        // So is the rest of the default configuration.
        toolbar: [
            'heading',
            'bold',
            'italic',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            'alignment',
            'link',
            'bulletedList',
            'numberedList',
            //'uploadImage',
            'blockQuote',
            'undo',
            'redo',
            'insertImage',
            'integralBox',
            'sumBox',
            'mathBox',
            'commandBox'
        ],
        image: {
            toolbar: [
                'imageStyle:full',
                'imageStyle:side',
                '|',
                'imageTextAlternative'
            ]
        }
    } )
    .then( editor => {
        console.log( 'Editor was initialized', editor );
        CKEditorInspector.attach( 'editor', editor );
        window.editor = editor;
    } )
    .catch( error => {
        console.error( error.stack );
    } );
    




/*
function paragraphHandler(node, lineArray){


}



function nodeHandler(node, latexArray){
  console.log("NODE:", node);

  if (node.nodeName == "P") {
    paragraphHandler(node, []);

    var nodes = node.childNodes;
    nodes.forEach( nodeHandler, latexArray);


  }

  var nodes = node.childNodes;
  nodes.forEach( nodeHandler, latexArray);
  //console.log("NODEs:", nodes);
}*/


function isEmpty(node) {
  // return true if empty, false otherwise
  if ( !node.outerText || node.outerText == "\\n") {
    return true
  } else {
    return false
  }
}


function ancestorHasClass(element, className, ignoreMathBox) {
  console.log("ignore math-box:", ignoreMathBox);
  if (!element || element.length === 0) {
    return false;
  }
  var parent = element.parentNode;
  do {
    if (parent === document) {
      break;
    }
    if (parent.className.indexOf(className) >= 0) {
      if (ignoreMathBox) {
        console.log("ignore!", parent.className);
        if (parent.className.indexOf("mathBox") >= 0) {
          console.log("return false");
          // this ancestor is a mathBox
          return false
        }
      }
      return true;
    }
  } while (parent = parent.parentNode);
  return false;
}


function hslTOrgb(hsl) {
  // return "latex rgb" str
  // Must be fractions of 1
  let sep = hsl.indexOf(",") > -1 ? "," : " ";
  hsl = hsl.substr(4).split(")")[0].split(sep);

  let h = hsl[0],
      s = hsl[1].substr(0,hsl[1].length - 1) / 100,
      l = hsl[2].substr(0,hsl[2].length - 1) / 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs((h / 60) % 2 - 1)),
      m = l - c/2,
      r = 0,
      g = 0,
      b = 0;
  
  if (0 <= h && h < 60) {
      r = c; g = x; b = 0;  
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }
    r = ((r + m) * 1).toPrecision(3);
    g = ((g + m) * 1).toPrecision(3);
    b = ((b + m) * 1).toPrecision(3);
  
    return String(r + ", " + g + ", " + b);
    //0.8, 0.4, 0.02
}


function rgbTOlatexRGB(rgb) {
  let sep = rgb.indexOf(",") > -1 ? "," : " ";
  rgb = rgb.substr(4).split(")")[0].split(sep);

  for (let R in rgb) {
    let r = rgb[R];
    if (r.indexOf("%") > -1) 
      rgb[R] = Math.round(r.substr(0,r.length - 1) / 100 * 255);
  }

  // Make r, g, and b fractions of 1
  let r = (rgb[0] / 255).toPrecision(3),
      g = (rgb[1] / 255).toPrecision(3),
      b = (rgb[2] / 255).toPrecision(3);

  return String(r + ", " + g + ", " + b);
}


function styledNode(node) {
  // converts css to latex, returns styled node as a string
  var str = "";

  console.log("node with style:", node);
  var styles = node.attributes.style;
  console.log("styles:", styles);

  var styleStr = styles.value;
  console.log("styleStr:", styleStr);


  // font color?
  var color = styleStr.match(/(;|^)(color:.*?;)/g);
  console.log("color:", color);

  if (color) {

    node.style.color = null;
    var colorStr = color[0];

    if ( colorStr.match(/hsl/g) ) {
      var hsl = colorStr.match(/hsl.*\)/g)[0];
      console.log("HSL!", hsl);

      return "\\textcolor[rgb]{" + hslTOrgb(hsl) + "}{" + ckTOlatex(node) + "}"
    }

    if ( colorStr.match(/rgb/g) ) {
      var rgb = colorStr.match(/rgb.*\)/g)[0];
      console.log("RGB!", rgb);
  
      return "\\textcolor[rgb]{" + rgbTOlatexRGB(rgb) + "}{" + ckTOlatex(node) + "}"
    } 

    alert("COLOR FORMAT ERROR");
    return ckTOlatex(node)
    
  } 
    

  // font size?
  var fontsize = styleStr.match(/font-size:.*?;/g);
  console.log("fontsize:", fontsize);

  if (fontsize) {

    node.style.fontSize = null;
    var fontSizeStr = fontsize[0];

    if ( !ancestorHasClass(node, "simple-box", false) ) {
      //font size can not be changed inside a simple-box. TODO: add \scalebox support

      if ( fontSizeStr.match(/rem/g) ) {

        var sizeStr = fontSizeStr.match(/\d[.]*\d*/g);
        var size = parseFloat(sizeStr)
        console.log("Font-size (rem):", size);
        var pt = Math.round( size/ptToRem ); // pt is always an integer in latex
        console.log("Font-size (pt):", pt);
        var ptStr = String(pt);
  
        return "{" + ptToLatex[ptStr] + ckTOlatex(node) + "}"
    
      }
    
      alert("UNKNOWN FONT-SIZE UNIT");
      return ckTOlatex(node)
        
    } else {
      console.log("This node is inside a simple-box");
      return ckTOlatex(node)
    }

  } 

  
  // background color?
  var bgcolor = styleStr.match(/background-color:.*?;/g);
  console.log("bg-color:", bgcolor);

  if (bgcolor) {

    node.style.backgroundColor = null;
    var bgStr = bgcolor[0];

    if ( !ancestorHasClass(node, "simple-box") ) {
      //this node is NOT inside a symbol or math-box => this node is NOT inside math-mode
      //this is important, because colorbox can't be applied inside math-mode

      if ( bgStr.match(/hsl/g) ) {
        var hsl = bgStr.match(/hsl.*\)/g)[0];
        console.log("HSL!", hsl);
    
        return "\\colorbox[rgb]{" + hslTOrgb(hsl) + "}{" + ckTOlatex(node) + "}"
    
      } 

      if ( bgStr.match(/rgb/g) ) {
        var rgb = bgStr.match(/rgb.*\)/g)[0];
        console.log("RGB!", rgb);
      
        return "\\colorbox[rgb]{" + rgbTOlatexRGB(rgb) + "}{" + ckTOlatex(node) + "}"
      } 
    
      alert("COLOR FORMAT ERROR");
      return ckTOlatex(node)
        
    } else {
      console.log("This node is already inside math-mode");
      return ckTOlatex(node)
    }
  
  }


  // if this point is reached, this node has no more interesting style properties
  node.removeAttribute("style");
  return ckTOlatex(node)
  
}


function symbolTOlatex(node) {
  // return symbol in latex-syntax as a string
  console.log("symbol-node:", node);
  var str = "";

  node.classList.forEach( klass => {
    if ( classToLatex[klass] ) {
      console.log("Spännande klass:", klass);
      str = classToLatex[klass](node);
    }
  })

  return str

  /*
  if ( ancestorHasClass(node, "simple-box") || !findMathInText) {
    //this symbol is inside another symbol or math-box, or findMathInText is disabled
    return str
  } else {
    return "$" + str + "$"
  }
  */
}


function ckTOlatex(node){
  console.log("NODE:", node);

  if ( typeof node == "string" ) {
    console.log("type str, return:", node);
    return node
  }

  if ( node.nodeName === "#text" ) {
    console.log("type #text");
    var textStr = String(node.nodeValue);
    if (textStr.includes('\u00A0')){
      var textStr = textStr.replace(/\u00A0/g, ' '); // replace non-breaking-space with normal space
    }
    if (textStr.includes('\u2060')){
      var textStr = textStr.replace(/\u2060/g, ''); // replace zero-width-non-breaking-space with nothing
    }
    console.log("return:", textStr);
    return textStr;
  }

  if ( node.nodeName === "P" ) {
    console.log("type p, childNodes:", node.childNodes);
    var s = "\\\\"; //new line
    node.childNodes.forEach( node => s += ckTOlatex(node) );
    console.log("return:", s);
    return s
  }

  if ( node.nodeName === "BR" ) {
    console.log("type BR, node:", node);
    if ( node.outerHTML.includes("data-cke-filler") ) {
      // not a true linebreak, just a filler
      console.log("just a filler");
      return ""
    } else {
      return "\\\\"
    }
  }

  if ( node.nodeName === "SPAN" ) {

    if (node.attributes.length > 0) {
      console.log("ATTRIBUTES:", node.attributes);

      if (node.attributes.style) {
        // has style
        return styledNode(node);

      } else {
      if (node.classList) {
        console.log("CLASSLIST:", node.classList);

        if ( node.classList.contains("command") && node.classList.contains("ck-widget") ) {
          // command-container
          return ckTOlatex(node.firstChild.firstChild) + " "
        }

        if ( node.classList.contains("simple-box") && node.classList.contains("mathBox") ) {
          // mathbox-container
          var s = "$"; // math start
          node.firstChild.childNodes.forEach( node => s += ckTOlatex(node) );
          return s + "$"
        }

        if (node.classList.contains("simple-box")) {
          // is simple-box
          return symbolTOlatex(node);
        } 

      }}

    }

    //boring span
    var s = "";
    node.childNodes.forEach( node => s += ckTOlatex(node) );
    console.log("boring span, return:", s);
    return s

  }

}

function insertSource(latexSource){
  document.getElementById("latex-source").value = latexSource;
}

function includePackages() {
  // return mandatory packages as a string in latex syntax
  var packages = ''
  if (ckDependencies) {
    packages = '\\usepackage{' + ckDependencies[0];
    ckDependencies.slice(1).forEach((pac) => {
      packages += ',' + pac;
    })
  }
  packages += '}'
  console.log("CK-dependencies:", packages)
  return packages
}


$( "#CKtoLATEX" ).on( "click", function() {

  var editorHTML = $( ".ck-content" ).first()[0];

  var editorNodes = editorHTML.childNodes;
  console.log( editorNodes );

  // create local copy of nodes instead of live
  var editorNodesCopy = editorHTML.cloneNode(true).childNodes;
  
  var latexStr = "";
  editorNodesCopy.forEach( node => latexStr += ckTOlatex(node) );

  if (latexStr.includes("$$")){
    latexStr = latexStr.replace(/\$\$/g, ""); //remove dubble $$
  }

  console.log("ckToLATEXSTR:", latexStr);

  var latexSource = includePackages() + '\\begin{document}' + latexStr + '\\end{document}';

  var latexHead = document.getElementById("latex-head").value;
  console.log("Head:", latexHead);

  if (latexHead) {
    latexSource = latexHead + latexSource;
  }

  console.log("latexSource:", latexSource);
  insertSource(latexSource);

});
  


//editor.execute( 'fontSize', { value: 'tiny' } );

//TODO: line-height, bugs, IntegralBox-container upcast/downcast attributes => integral font size, font family size?/bold, image-functions, indentation

// mathmode-box: everything in this box will be rendered as math
// findMathInText = false: assume all math is in mathmode-boxes

/*
\DeclareMathSizes{5}     {5}   {5}  {5}
\DeclareMathSizes{6}     {6}   {5}  {5}
\DeclareMathSizes{7}     {7}   {5}  {5}
\DeclareMathSizes{8}     {8}   *{6}  {5}
\DeclareMathSizes{9}     {9}   *{7}  {5}
\DeclareMathSizes{10}   {10}   {7}  {5}
\DeclareMathSizes{12}   {12}   *{9}  *{7}
*/


// not exactly correct in editor with current solution

/*
CHANGES IN FILLER.JS:
//export const NBSP_FILLER = domDocument => domDocument.createTextNode( '\u00A0' );
export const NBSP_FILLER = domDocument => {
	const fillerBr = domDocument.createElement( 'span' );
	return fillerBr;
};
*/


function corrBracket(str, start){
  // return position of corresponding bracket
  var depth = 0;
  var len = str.length;
  var i = start;
  while (i < len) {
    if (str[i] == "{") {
      depth += 1;
    }
    if (str[i] == "}") {
      depth -= 1;
    }
    if (depth == 0) {
      break
    }
    i += 1;
    if (i == len) {
      alert("Missing }");
      return null
    }
  }
  return i
}


function commandBox(node){
  // return node inserted into a command-box
  return '<span class="command ck-widget"><span class="small-box text-box command ck-editor__editable ck-editor__nested-editable">'+node+'</span></span>';
}

function mathBox(node){
  // return node inserted into a mathBox
  return '<span class="simple-box mathBox ck-widget"><span class="small-box text-box mathBox ck-editor__editable ck-editor__nested-editable">'+node+'</span></span>';
}



function latexTOck(node){

  var nodeLen = node.length;
  if (nodeLen <= 0) {
    console.log("node is empty");
    return ""
  }

  /*
  var mathStart = node.indexOf('$');
  var doubleMathStart = node.indexOf('$$');
  var groupStart = node.indexOf('{');
  var commandStart = node.indexOf('\\');

  if (mathStart < 0 && commandStart < 0 && groupStart < 0) {
    // no more commands
    console.log("node more commands found");
    return node
  }
  */

  var something = node.match(/[${\\]/);
  if (!something) {
    // only text is left (hopefully)
    console.log("node more commands found");
    return node
  }

  if (something == "{") {
    // a group has been found
    var groupStart = something.index;
    var groupEnd = corrBracket(node, start);

    // is this bracket a "ghost" bracket?
    var substr = node.slice(groupStart+1, groupEnd);
    var command = substr.match(/^\s*?\\[_@a-zA-Z]+/); // looking for command at start of bracket
    if (command) {
      // do we know this command? DONT FORGET THIS
      console.log("command:", command);

    } else {
      // keep brackets (we don't know what they are for)
      return node.slice(0, groupStart) + commandBox("{") + latexTOck( node.slice(groupStart+1, groupEnd) ) + commandBox("}") + latexTOck( node.slice(groupEnd+1) )
    }
  }


  if (something == "$") {
    // math mode has been found
    if (node[something.index+1] == "$") {
      //double-math-mode
      var math = node.match(/\$\$.*?[^\\]\$\$/);
      var mathContentStart = math.index + 2;
      var mathContentEnd = math.index + math.length - 2;
    } else {
      // normal math mode
      var math = node.match(/\$.*?[^\\]\$/);
      var mathContentStart = math.index + 1;
      var mathContentEnd = math.index + math.length - 1;
    }
    console.log("MATH:", math);
    if (!math) {
      alert("Missing $");
    }
    return node.slice(0, math.index) + mathBox( latexTOck( node.slice(mathContentStart, mathContentEnd) ) ) + latexTOck( node.slice(math.index+math.length) )
  }


 //problem: command inside command KEEP WORKING FROM HERE

  if (commandStart >= 0 && commandStart < mathStart) {
    // command is first
    //case 1: command of type \noindent...
    var command = node.match(/\\[_@a-zA-Z]+/);

    //case 2: command of type \$, \\ etc
    var command = node.match(/\\[^_@a-zA-Z]/);



    /*
    if (!command) {
      alert("Command error")
    }
    */

  }




}





/*
function findChildren(node){
  var childNodes = [];
  //var i = 0;
  var end = node.length;
  var iStart = 0;



  while ( iStart <= end) {
    var child = node.match()



  }


}
*/



$( "#LATEXtoCK" ).on( "click", function() {

  var latexSource = document.getElementById("latex-source").value;
  console.log("Source code:", latexSource);

  var docStart = latexSource.indexOf("\\begin{document}");
  console.log("Start:", docStart); //625
  var docEnd = latexSource.indexOf("\\end{document}");
  console.log("End:", docEnd); //3157

  if (docStart < 0 || docEnd < 0) {
    alert("Fatal Error");
    console.log("No start or end of document found");
  }

  var latexHead = latexSource.slice(0, docStart);
  console.log("Head:", latexHead);
  var latexFooter = latexSource.slice(docEnd + 14);
  console.log("Foot:", latexFooter);
  var latexDoc = latexSource.slice(docStart + 16, docEnd);
  console.log("Document:", latexDoc);
  
});


$( document ).ready(function() {
  console.log("Document ready!");
  showsamplepdf()
  loadKeyboardTestpage();
});

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

function showsamplepdf(){
  var pdf_dataurl = samplepdfs['pdf1'].data;
  //console.log("PDF DATA URL:", pdf_dataurl);
  var blob = dataURLtoBlob(pdf_dataurl);
  var objectURL = URL.createObjectURL(blob);
  document.getElementById("pdf-js-viewer").src = objectURL;
}

// TODO: test keyboard with ck editor

function loadKeyboardTestpage(){
  console.log("Insert keyboard testpage");
  fetch('testpage.html')
  .then(response=> response.text())
  .then(text=> document.getElementById('keyboardtestbox').innerHTML = text)
  .then(loadKeyboard())
  .then( // extra delay because idk
        setTimeout(function() {
            initbuilderbuttons()
        }, 100)
        )
}