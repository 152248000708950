import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import colorToHex from '../ui/colortohex';
import newStyle from '../ui/newstylestring';

function contConv (thiseditor, className, toolbarName) {
    const modelName = toolbarName + '-container';

    thiseditor.conversion.for( 'upcast' ).elementToElement( {
        view: {
            name: 'span',
            classes: [className],
            //src: imgSRC
        },
        model: ( viewElement, { writer: modelWriter } ) => {
            return modelWriter.createElement( modelName, {
                class: [className, 'ck-widget'].join(' '),
                //fontSize: "30px"
                //src: imgSRC
            } );
        }
    } );


    thiseditor.conversion.for( 'downcast' ).elementToElement( {
        model: modelName,
        view: ( modelValue, { writer: viewWriter } ) => {
            console.log("CREATE", modelName);

            const section = viewWriter.createContainerElement( 'span', {
                class: [className].join(' '),
                //style: "font-size: 30px;"
                //style: "color: #0000ff;"
            });

            return toWidget( section, viewWriter, { label: 'simple box widget' } );
            
        },
    } );

}

export default contConv