function smallboxSchema (thiseditor, toolbarName, boxName){

    thiseditor.model.schema.register( toolbarName+'-'+boxName, {
        // Cannot be split or left by the caret.
        isLimit: true,
        // Allow content which is allowed in blocks (i.e. text with attributes).
        allowContentOf: '$block', //block
        allowIn: toolbarName+'-container'
    });

}

export default smallboxSchema