import symlayouts from './layoutsfolder/symlayouts.js';

function makesymlayoutlistitem(name, id){
    return '<div class="listel" id="'+id+'"><div class="symlaylistnamebox">'+name+'</div></div>'
}


function makelaylist(){
    // make list of layouts in element "listofsymlayouts"

    var listbox = $('#listofsymlayouts');

    for (const layout of Object.entries(symlayouts)) {
        var id = layout[0];
        var name = id;
        listbox.append(makesymlayoutlistitem(name, id))
    }

}

export {makesymlayoutlistitem, makelaylist, symlayouts}