function contSchema (thiseditor, toolbarName){

    thiseditor.model.schema.addAttributeCheck( context => {
        if ( context.endsWith( toolbarName+'-container' ) ) {
            return false; // disallow attributes for this element
        }
    } );

    thiseditor.model.schema.register( toolbarName+'-container', {
        isObject: true,
        isBlock: true,
        isInline: true,
        allowWhere: '$text'
    });

}

export default contSchema