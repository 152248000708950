function insertMathMode(node) {
    console.log("NODE:", node);

    if ( typeof node == "string" ) {
        // just pure text
        return node
    }
    if ( node.nodeName === "#text" ) {
        // node with just text
        console.log("type #text");
        return String(node.data);
    }
    if (node.classList.contains("mathBox")) {
        // this node is a mathBox
        return String(node.outerHTML);
    }
    if (node.classList.contains("simple-box")) {
        // this node is a simple-box, but is not inside a mathBox (!)
        return '<span class="simple-box mathBox ck-widget" contenteditable="false"><span class="small-box text-box mathBox">'+String(node.outerHTML)+'</span></span>';
    }
    if (node.classList.contains("command")) {
        // this is a command-box
        return String(node.outerHTML);
    }
    //else:
    var s = "";
    node.childNodes.forEach( node => s += insertMathMode(node) );
    return s
}


function removeMathMode(node) {
    if (node.classList.contains("mathBox")) {
        // this node is a mathBox
        return String(node.firstChild.innerHTML);
    }
    //else:
    var s = "";
    node.childNodes.forEach( node => s += insertMathMode(node) );
    return s
}


function newHTML( data ) {
    // return html as a string with mathBox in the right places
    var htmlString = data.dataTransfer.getData( 'text/html' );
    var targetClassObj = data.target.parent._classes;
    var newHtmlString = htmlString;

    if (targetClassObj.has("simple-box")) {
        // target must be inside a math box
        console.log("Target is inside a math box!");
        if (htmlString.includes("mathBox")) {
            //remove all math-boxes
            console.log("Remove all math-boxes!");
            var tempDiv = document.createElement('div');
            tempDiv.innerHTML = htmlString;
            var editorNodes = tempDiv.childNodes;
            newHtmlString = "";
            editorNodes.forEach( node => newHtmlString += removeMathMode(node) );
        }

    } else {

        if (htmlString.includes("simple-box")) {
            // object includes something that needs to be in math-mode
            console.log("Something here needs to be in math-mode!");
            var tempDiv = document.createElement('div');
            tempDiv.innerHTML = htmlString;
            console.log("Tempdiv:", tempDiv);
            var editorNodes = tempDiv.childNodes;
            newHtmlString = "";
            editorNodes.forEach( node => newHtmlString += insertMathMode(node) );
        }

    }

    console.log("New htmlString:", newHtmlString);
    return newHtmlString

}

export default newHTML