import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import Command from '@ckeditor/ckeditor5-core/src/command';
import makesvg from '../ui/makeSVG';

import buttonConstructor from '../ui/buttonConstructor1';
import RefreshCommand from '../ui/refreshCommand1';
import newPath from '../ui/newPath2';
import contSchema from '../defineSchemas/containerSchema2';
import contConv from '../defineConverters/containerConverter1';
import iconSchema from '../defineSchemas/iconSchema1';
import iconConv from '../defineConverters/iconConverter1';
import smallboxSchema from '../defineSchemas/smallboxSchema2';
import smallboxConv from '../defineConverters/smallboxConverter1';
import newHTML from '../ui/clipboardMathBox1';

const className = 'mathBox';
const toolbarName = 'mathBox';
const displayName = 'MathBox';
const execCommand = 'insertMathBox';
const latexName = '';
//console.log("imgSRC:", MakeSVG(latexName));
const imgSRC = '';

export default class EverythingYouNeedToKnowAboutThisSymbol extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        // define all components of this symbol

        contSchema(this.editor, toolbarName);
        contConv(this.editor, className, toolbarName);

        this.on( 'insert', ( eventInfo, data ) => {
            console.log("MATHBOX WAS INSERTED");
        } );

        
        //smallboxSchema(this.editor, toolbarName, 'upperBox');
        //smallboxConv(this.editor, className, toolbarName, 'upperBox', 'upper-box');

        //smallboxSchema(this.editor, toolbarName, 'lowerBox');
        //smallboxConv(this.editor, className, toolbarName, 'lowerBox', 'lower-box');

        smallboxSchema(this.editor, toolbarName, 'textBox');
        smallboxConv(this.editor, className, toolbarName, 'textBox', 'text-box');

        //iconSchema(this.editor, toolbarName);
        //iconConv(this.editor, className, latexName, toolbarName, imgSRC);

        // insert symbol
        this.editor.commands.add( execCommand, new InsertSymbol( this.editor ) );

        // create a button for this symbol
        buttonConstructor.call(this, this.editor, toolbarName, displayName, execCommand);

        this.editor.editing.view.document.on( 'clipboardInput', ( eventInfo, data) => {
            // add or remove mathBox in editor on copy-paste/drag-drop
            var htmlString = data.dataTransfer.getData( 'text/html' );
            if (htmlString.includes("simple-box")) {
                data.content = this.editor.data.htmlProcessor.toView( newHTML( data ) );
            } // otherwise, no modifications are necessary
        } );

    }

}


function createSymbol( writer ) {
    // construct this symbol, component by component

    const symbolContainer = writer.createElement( toolbarName+'-container');

    //writer.append( writer.createElement( toolbarName+'-icon' ), symbolContainer );
    //writer.append( writer.createElement( toolbarName+'-lowerBox' ), symbolContainer );
    //writer.append( writer.createElement( toolbarName+'-upperBox' ), symbolContainer );
    writer.append( writer.createElement( toolbarName+'-textBox' ), symbolContainer );

    return symbolContainer;
}


class InsertSymbol extends Command {
    // insert this symbol in the editor

    execute() {
        this.editor.model.change( writer => {
            // insert symbol at the current selection position in a way that will result in creating a valid model structure
            this.editor.model.insertContent( createSymbol( writer ) );
            console.log("INSERT MATH BOX!!!");

            // move cursor to first small-box
            var path = this.editor.model.document.selection.focus.path;
            var newpath = newPath(path);
            var root = this.editor.model.document.getRoot();
            var newpos = writer.createPositionFromPath(root, newpath);
            writer.setSelection( newpos );
            //writer.setAttribute( 'color', 'red', symbolBox );

        } );
        console.log("INSERT NEW SYMBOL");
        //FindSymbols(); // update list of symbols
    }
    //refresh() { new RefreshCommand(this, toolbarName) }
    // since all symbols are allowed in $text, and text is allowed everywhere in the editor, it is not necessary to refresh "isEnabled"

}

const properties = {
    className: className,
    toolbarName: toolbarName,
    displayName: displayName,
    execCommand: execCommand,
    latexName: latexName,
    imgSRC: imgSRC
}

export {properties}