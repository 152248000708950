// translate alligator key id's to key code
var keyboardkey = {
    220: 11,
    49: 12,
    50: 13,
    51: 14,
    52: 15,
    53: 16,
    54: 17,
    55: 18,
    56: 19,
    57: 110,
    48: 111,
    81: 22,
    87: 23,
    69: 24,
    82: 25, 
    84: 26,
    89: 27,
    85: 28,
    73: 29,
    79: 210,
    80: 211,
    65: 31,
    83: 32,
    68: 33,
    70: 34,
    71: 35,
    72: 36,
    74: 37,
    75: 38,
    76: 39,
    90: 43,
    88: 44,
    67: 45,
    86: 46,
    66: 47,
    78: 48,
    77: 49,
    188: 410,
    190: 411
}

export default keyboardkey