function smallboxSchema (thiseditor, toolbarName, boxName){

    thiseditor.model.schema.addChildCheck( (context, childDefinition) => {
        //console.log("Childdefinition:", childDefinition);
        if ( context.endsWith( toolbarName+'-'+boxName ) && childDefinition.name.includes('container') ) {
            return false; // disallow containers in this element
        }
    } );

    thiseditor.model.schema.register( toolbarName+'-'+boxName, {
        // Cannot be split or left by the caret.t
        isLimit: true,
        // Allow content which is allowed in blocks (i.e. text with attributes).
        allowChildren: '$text', //block
        allowIn: toolbarName+'-container'
    });

}

export default smallboxSchema