var kuisymbols = {
    '{tab}': '<span class="material-symbols-outlined">sync_alt</span>',
    '{shift}': '<span class="material-symbols-outlined">arrow_upward</span>',
    '{bksp}': '<span class="material-symbols-outlined">backspace</span>',
    '{alt}': '<span class="material-symbols-outlined">keyboard_option_key</span>',
    '{accept}': 'ctrl',
    '{cancel}': 'ctrl',
    '{enter}': '<span class="material-symbols-outlined">keyboard_return</span>',
    '{space}': '<span class="material-symbols-outlined">space_bar</span>'
};

var ksymbols = {
    '{tab}': '<span class="material-symbols-outlined">sync_alt</span>',
    '{shift}': '<span class="material-symbols-outlined">arrow_upward</span>',
    '{bksp}': '<span class="material-symbols-outlined">backspace</span>',
    '{alt}': 'alt/option',
    '{accept}': 'ctrl',
    '{cancel}': 'ctrl',
    '{enter}': '<span class="material-symbols-outlined">keyboard_return</span>'
};

export default {kuisymbols, ksymbols}