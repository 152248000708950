function contSchema (thiseditor, toolbarName){

    thiseditor.model.schema.addAttributeCheck( context => {
        if ( context.endsWith( toolbarName+'-container' ) ) {
            return true; // allow attributes for this element
        }
    } );

    /*
    thiseditor.model.schema.addChildCheck( (context, childDefinition) => {
        if ( context.endsWith( toolbarName+'-container' ) && childDefinition.name == toolbarName+'-container' ) {
            console.log("nesting");
            return false; // disallow nesting of this container
        }
    } );
    */

    thiseditor.model.schema.register( toolbarName+'-container', {
        isObject: true,
        isBlock: true,
        isInline: true,
        allowAttributesOf: '$text',
        //allowAttributes: ['fontSize'],
    
        // Allow in places where other blocks are allowed (e.g. directly in the root).
        allowWhere: '$text' //text
    });

}

export default contSchema