import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import SimpleIntegral from './config/simpleIntegral';
import SimpleSum from './config/simpleSum';
import MathBox from './config/mathBox';
import CommandBox from './config/command';

export default class Symbols extends Plugin {
    static get requires() {
        return [
            SimpleIntegral,
            SimpleSum,
            MathBox,
            CommandBox
        ];
    }
}

//editor.execute( 'insertSimpleBox' ); will insert a new box
//editor.execute( 'insertSimpleIntegral' ); will insert integral
//editor.execute( 'insertSimpleSum' ); will insert sum